import React from 'react'

import styles from './promo-banner.module.scss'
import { history } from '../../../..'

const PromotionBanner = () => {
  return (
    <div className={styles.promoBanner}>
      <div className="homepage-container">
        <div className="row gap-md-3 gap-lg-4 promotion-banner">
          {/* <div
            className="col-12 col-md-4 promotion-banner__card"
            onClick={() => history.push('/secured/register')}
          >
            <div className="promotion-banner__card__icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_9345_163810)">
                  <path
                    d="M14.1482 0.8125C-1.62201 2.52135 -5.23002 25.9419 15.0447 28.9177C30.36 29.2781 36.9366 2.34117 14.1482 0.8125Z"
                    fill="#DEFCEC"
                  />
                  <path
                    d="M20.4496 11.8005C20.2334 7.9086 16.3955 6.48517 14.2336 6.9357C13.2726 7.5663 10.0712 9.00769 9.99915 11.8906C9.92707 14.7735 13.4224 17.1158 14.5036 17.3861C16.4334 17.8684 20.6658 15.6924 20.4496 11.8005Z"
                    fill="white"
                  />
                  <path
                    d="M23.8737 25.8527C19.4052 29.6725 12.5224 28.7356 9.4593 27.6545L6.48633 25.5824L6.49176 25.569C7.6195 22.7734 9.46683 18.1939 14.144 17.4743C20.9909 16.8978 22.7025 23.2401 23.8737 25.8527Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.1344 16.7546C17.7217 16.7546 19.8191 14.6572 19.8191 12.07C19.8191 9.48268 17.7217 7.38528 15.1344 7.38528C12.5471 7.38528 10.4497 9.48268 10.4497 12.07C10.4497 14.6572 12.5471 16.7546 15.1344 16.7546ZM15.1344 17.6555C18.2192 17.6555 20.72 15.1548 20.72 12.07C20.72 8.98513 18.2192 6.48438 15.1344 6.48438C12.0496 6.48438 9.54883 8.98513 9.54883 12.07C9.54883 15.1548 12.0496 17.6555 15.1344 17.6555Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.8331 25.8791C6.83312 25.879 6.83316 25.8789 6.39699 25.7663C5.96083 25.6538 5.96088 25.6536 5.96094 25.6534L5.96109 25.6528L5.96155 25.651L5.96308 25.6452C5.96437 25.6403 5.9662 25.6334 5.96858 25.6247C5.97334 25.6071 5.9803 25.5817 5.98953 25.5492C6.00799 25.4842 6.03555 25.3903 6.07273 25.2716C6.14707 25.0344 6.26007 24.6977 6.41611 24.2946C6.72763 23.4898 7.21348 22.4131 7.91037 21.3329C9.2977 19.1826 11.5793 16.9375 15.0456 16.9375C18.512 16.9375 20.7936 19.1826 22.1809 21.3329C22.8778 22.4131 23.3637 23.4898 23.6752 24.2946C23.8312 24.6977 23.9442 25.0344 24.0186 25.2716C24.0557 25.3903 24.0833 25.4842 24.1018 25.5492C24.111 25.5817 24.118 25.6071 24.1227 25.6247C24.1251 25.6334 24.1269 25.6403 24.1282 25.6452L24.1297 25.651L24.1304 25.6534C24.1304 25.6536 24.1305 25.6538 23.6943 25.7663C23.2581 25.8789 23.2582 25.879 23.2582 25.8791L23.2581 25.8786L23.2572 25.8753C23.2567 25.8735 23.2561 25.8711 23.2553 25.8681C23.2546 25.8658 23.2539 25.863 23.2531 25.8599C23.2493 25.8458 23.2433 25.8241 23.2351 25.7952C23.2187 25.7375 23.1935 25.6515 23.1589 25.541C23.0896 25.3201 22.983 25.0022 22.835 24.6198C22.5384 23.8536 22.0784 22.8358 21.4239 21.8213C20.1085 19.7825 18.0658 17.8384 15.0456 17.8384C12.0255 17.8384 9.98278 19.7825 8.6674 21.8213C8.01294 22.8358 7.55284 23.8536 7.25626 24.6198C7.10824 25.0022 7.00165 25.3201 6.93241 25.541C6.8978 25.6515 6.87257 25.7375 6.85619 25.7952C6.848 25.8241 6.84203 25.8458 6.83821 25.8599C6.8363 25.867 6.83493 25.8721 6.83409 25.8753L6.83323 25.8786L6.8331 25.8791Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.045 28.2868C22.4586 28.2868 28.4685 22.2769 28.4685 14.8634C28.4685 7.44983 22.4586 1.43996 15.045 1.43996C7.63148 1.43996 1.6216 7.44983 1.6216 14.8634C1.6216 22.2769 7.63148 28.2868 15.045 28.2868ZM15.045 29.1877C22.9561 29.1877 29.3694 22.7745 29.3694 14.8634C29.3694 6.95228 22.9561 0.539062 15.045 0.539062C7.13392 0.539062 0.720703 6.95228 0.720703 14.8634C0.720703 22.7745 7.13392 29.1877 15.045 29.1877Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M36.5148 22.104C30.8098 16.6592 24.4417 19.8353 21.9708 22.104C19.3435 24.8263 19.1245 29.225 19.3435 31.0841C19.3435 35.0164 23.2844 37.89 25.2549 38.8353C32.161 41.3308 36.3897 37.4174 37.6408 35.1487C42.0696 29.5528 38.7355 24.1206 36.5148 22.104Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M29.1875 36.3978V22.3438C29.9082 22.3433 31.9803 23.1531 31.9803 24.2356L32.0704 26.9383H34.683C38.4668 29.0104 35.5839 31.8032 34.3226 32.1636L32.0704 32.0735C32.1004 32.9143 31.8001 34.7762 31.8001 34.7762C31.8001 34.7762 30.9893 36.6681 29.1875 36.3978Z"
                    fill="white"
                  />
                  <path
                    d="M29.3613 22.3405L29.3613 36.3945C28.5569 36.3952 26.9352 35.6745 26.9352 34.7729L26.8451 32.0702L24.5028 32.0702C21.9803 31.7999 21.9803 27.3855 24.4127 27.0252L26.9352 26.7549C26.9052 25.914 26.9352 24.9531 27.0253 24.4125C27.1289 23.7912 28.3767 22.3405 29.3613 22.3405Z"
                    fill="white"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.4585 39.0981C34.7823 39.0981 39.0981 34.7823 39.0981 29.4585C39.0981 24.1347 34.7823 19.8189 29.4585 19.8189C24.1347 19.8189 19.8189 24.1347 19.8189 29.4585C19.8189 34.7823 24.1347 39.0981 29.4585 39.0981ZM29.4585 39.9991C35.2799 39.9991 39.9991 35.2799 39.9991 29.4585C39.9991 23.6371 35.2799 18.918 29.4585 18.918C23.6371 18.918 18.918 23.6371 18.918 29.4585C18.918 35.2799 23.6371 39.9991 29.4585 39.9991Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M26.2168 25.1339C26.2168 23.3427 27.6688 21.8906 29.46 21.8906C31.2512 21.8906 32.7033 23.3427 32.7033 25.1339V27.1158H31.8024V25.1339C31.8024 23.8402 30.7537 22.7915 29.46 22.7915C28.1664 22.7915 27.1177 23.8402 27.1177 25.1339V27.1158H26.2168V25.1339ZM27.1177 31.8456V33.7825C27.1177 35.0762 28.1664 36.1249 29.46 36.1249C30.7537 36.1249 31.8024 35.0762 31.8024 33.7825V31.8906H32.7033V33.7825C32.7033 35.5737 31.2512 37.0258 29.46 37.0258C27.6688 37.0258 26.2168 35.5737 26.2168 33.7825V31.8456H27.1177Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.7841 26.2187C35.5753 26.2187 37.0273 27.6708 37.0273 29.462C37.0273 31.2532 35.5753 32.7052 33.7841 32.7052L31.8021 32.7052L31.8021 31.8043L33.7841 31.8043C35.0777 31.8043 36.1264 30.7556 36.1264 29.462C36.1264 28.1684 35.0777 27.1197 33.7841 27.1197L31.8922 27.1197L31.8922 26.2187L33.7841 26.2187ZM27.1174 27.1197L25.1355 27.1197C23.8418 27.1197 22.7931 28.1684 22.7931 29.462C22.7931 30.7556 23.8418 31.8043 25.1355 31.8043L27.1174 31.8043L27.1174 32.7052L25.1355 32.7052C23.3443 32.7052 21.8922 31.2532 21.8922 29.462C21.8922 27.6708 23.3443 26.2187 25.1355 26.2187L27.1174 26.2187L27.1174 27.1197Z"
                    fill="#26A69B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9345_163810">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="promotion-banner__card__title">Start Shopping</div>
            <div className="promotion-banner__card__description">
              Register in minutes and access to the TTW catalog of products from all over
              the world
            </div>
          </div> */}
          <div
            className="col-12 col-md-4 promotion-banner__card"
            onClick={() => history.push('/secured/register')}
          >
            <div className="promotion-banner__card__icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_9345_163830)">
                  <path
                    d="M25.4054 30.5423V17.9297C25.4054 17.9297 28.1496 19.0307 30 19.1009C31.9307 19.1741 34.8648 18.0198 34.8648 18.0198V30.5423H38.9189L30.1801 39.5513L21.4414 30.6324L25.4054 30.5423Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M14.5946 9.63922C14.5946 9.63922 14.5946 20.5401 14.5946 22.2518C11.1712 20.7203 8.10815 21.2608 8.10815 21.2608L5.13518 22.2969L5.13518 9.68465L1.17121 9.63922L9.90995 0.810388L18.5586 9.63922L14.5946 9.63922Z"
                    fill="#DEFCEC"
                  />
                  <path
                    d="M14.6797 30.7293H14.0094C13.8797 30.5371 13.7403 30.3281 13.5914 30.1023C13.4473 29.8765 13.3031 29.6458 13.159 29.4104C13.0148 29.175 12.8803 28.9443 12.7554 28.7185C12.6304 28.4927 12.5199 28.2837 12.4238 28.0915C12.4527 28.029 12.5007 27.9714 12.568 27.9185C12.6352 27.8609 12.7217 27.832 12.8274 27.832C12.9427 27.832 13.034 27.8609 13.1013 27.9185C13.1686 27.9714 13.2358 28.0603 13.3031 28.1852C13.3464 28.2669 13.404 28.3702 13.4761 28.4951C13.5482 28.62 13.6226 28.7546 13.6995 28.8987C13.7812 29.0428 13.8629 29.187 13.9445 29.3311C14.0262 29.4753 14.0983 29.6074 14.1608 29.7275C14.228 29.8476 14.2833 29.9437 14.3265 30.0158H14.3626C14.5115 29.7564 14.6461 29.5113 14.7662 29.2807C14.8911 29.05 15.0088 28.8218 15.1193 28.596C15.2346 28.3702 15.3524 28.1395 15.4725 27.9041C15.5157 27.8801 15.5662 27.8633 15.6238 27.8537C15.6815 27.8392 15.7343 27.832 15.7824 27.832C15.8929 27.832 15.9794 27.8609 16.0418 27.9185C16.1091 27.9714 16.1427 28.0482 16.1427 28.1491C16.1427 28.202 16.1091 28.3005 16.0418 28.4446C15.9794 28.584 15.8857 28.7642 15.7608 28.9852C15.6358 29.2014 15.4821 29.4585 15.2995 29.7564C15.1217 30.0494 14.9151 30.3738 14.6797 30.7293ZM13.9734 30.4122H14.7157V32.2356C14.6869 32.2452 14.6388 32.2573 14.5716 32.2717C14.5091 32.2861 14.4442 32.2933 14.377 32.2933C14.2328 32.2933 14.1295 32.2669 14.0671 32.214C14.0046 32.1564 13.9734 32.0675 13.9734 31.9473V30.4122Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M9.94215 32.3503C9.57218 32.3503 9.25507 32.2831 8.9908 32.1485C8.72654 32.014 8.52473 31.8242 8.3854 31.5792C8.25086 31.3293 8.18359 31.041 8.18359 30.7143V30.1594H8.93314V30.6711C8.93314 31.017 9.02924 31.2789 9.22143 31.4567C9.41362 31.6344 9.65386 31.7233 9.94215 31.7233C10.2304 31.7233 10.4707 31.6344 10.6629 31.4567C10.8551 31.2789 10.9512 31.017 10.9512 30.6711V30.1594H11.7079V30.7143C11.7079 31.041 11.6382 31.3293 11.4989 31.5792C11.3644 31.8242 11.165 32.014 10.9007 32.1485C10.6364 32.2831 10.3169 32.3503 9.94215 32.3503ZM8.93314 30.4332H8.18359V27.8747C8.21242 27.8651 8.26047 27.8555 8.32774 27.8458C8.39501 27.8314 8.46227 27.8242 8.52954 27.8242C8.66888 27.8242 8.76978 27.8506 8.83224 27.9035C8.89951 27.9515 8.93314 28.0404 8.93314 28.1702V30.4332ZM11.7079 30.4332H10.9512V27.8747C10.9848 27.8651 11.0328 27.8555 11.0953 27.8458C11.1626 27.8314 11.2322 27.8242 11.3043 27.8242C11.4437 27.8242 11.5446 27.8506 11.607 27.9035C11.6743 27.9515 11.7079 28.0404 11.7079 28.1702V30.4332Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M6.19855 29.6984V30.2749H4.33909V29.6984H6.19855ZM5.46342 30.2749L5.6436 29.8353C5.99435 29.8353 6.28504 29.8882 6.51567 29.9939C6.75111 30.0948 6.92648 30.2341 7.0418 30.4119C7.15711 30.5897 7.21477 30.7915 7.21477 31.0173C7.21477 31.4641 7.05141 31.7957 6.72468 32.0119C6.40276 32.2281 5.9415 32.3362 5.3409 32.3362C5.21597 32.3362 5.08624 32.3314 4.95171 32.3218C4.81717 32.317 4.68264 32.3026 4.5481 32.2785C4.41357 32.2593 4.28624 32.2329 4.16612 32.1993C3.94991 32.132 3.8418 32.0047 3.8418 31.8173L3.8418 28.2137C3.8418 28.1272 3.86342 28.0623 3.90666 28.0191C3.95471 27.971 4.01957 27.935 4.10126 27.911C4.26462 27.8629 4.4448 27.8293 4.6418 27.8101C4.8436 27.7909 5.05501 27.7812 5.27603 27.7812C5.83819 27.7812 6.27303 27.8797 6.58054 28.0767C6.89285 28.2737 7.049 28.5764 7.049 28.9849C7.049 29.1963 6.99375 29.3861 6.88324 29.5542C6.77273 29.7224 6.60216 29.8545 6.37153 29.9506C6.1409 30.0467 5.843 30.0948 5.47783 30.0948L5.41297 29.6984C5.70606 29.6984 5.92468 29.6407 6.06882 29.5254C6.21297 29.4053 6.28504 29.2371 6.28504 29.0209C6.28504 28.8095 6.20096 28.6509 6.03279 28.5452C5.86462 28.4347 5.61477 28.3794 5.28324 28.3794C5.1439 28.3794 5.01417 28.3843 4.89405 28.3939C4.77873 28.4035 4.67543 28.4179 4.58414 28.4371V31.6587C4.68504 31.6828 4.80516 31.702 4.9445 31.7164C5.08864 31.726 5.22798 31.7308 5.36252 31.7308C5.71327 31.7308 5.97994 31.6707 6.16252 31.5506C6.34991 31.4305 6.4436 31.2455 6.4436 30.9957C6.4436 30.7698 6.35951 30.5945 6.19135 30.4695C6.02798 30.3398 5.78534 30.2749 5.46342 30.2749Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M34.6113 8.81358H35.3681V11.473L35.0149 11.5379C34.8948 11.5379 34.7963 11.4995 34.7194 11.4226C34.6474 11.3457 34.6113 11.2472 34.6113 11.1271V8.81358ZM35.0149 11.5379V10.9037H37.3357C37.3597 10.9325 37.3813 10.9733 37.4005 11.0262C37.4197 11.079 37.4293 11.1391 37.4293 11.2064C37.4293 11.3121 37.4029 11.3938 37.3501 11.4514C37.2972 11.5091 37.2275 11.5379 37.1411 11.5379H35.0149ZM35.3681 9.55592H34.6113V7.15592C34.645 7.14631 34.693 7.1367 34.7555 7.12709C34.8227 7.11268 34.8924 7.10547 34.9645 7.10547C35.1038 7.10547 35.2047 7.1319 35.2672 7.18475C35.3345 7.2328 35.3681 7.32168 35.3681 7.45141V9.55592Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M30.9805 8.81358H31.7372V11.473L31.3841 11.5379C31.264 11.5379 31.1655 11.4995 31.0886 11.4226C31.0165 11.3457 30.9805 11.2472 30.9805 11.1271V8.81358ZM31.3841 11.5379V10.9037H33.7048C33.7288 10.9325 33.7504 10.9733 33.7697 11.0262C33.7889 11.079 33.7985 11.1391 33.7985 11.2064C33.7985 11.3121 33.7721 11.3938 33.7192 11.4514C33.6664 11.5091 33.5967 11.5379 33.5102 11.5379H31.3841ZM31.7372 9.55592H30.9805V7.15592C31.0141 7.14631 31.0622 7.1367 31.1246 7.12709C31.1919 7.11268 31.2615 7.10547 31.3336 7.10547C31.473 7.10547 31.5739 7.1319 31.6363 7.18475C31.7036 7.2328 31.7372 7.32168 31.7372 7.45141V9.55592Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M27.123 8.75564H27.8654V11.4728C27.8414 11.4824 27.7957 11.4968 27.7285 11.516C27.666 11.5304 27.5987 11.5376 27.5267 11.5376C27.4065 11.5376 27.308 11.4992 27.2312 11.4223C27.1591 11.3454 27.123 11.2469 27.123 11.1268V8.75564ZM27.8654 9.90159L27.123 9.89438V7.55925C27.123 7.43432 27.1591 7.33583 27.2312 7.26375C27.308 7.18688 27.4065 7.14844 27.5267 7.14844C27.5987 7.14844 27.666 7.15564 27.7285 7.17006C27.7957 7.18447 27.8414 7.19889 27.8654 7.2133V9.90159ZM27.5267 9.59168V8.97907H29.7032C29.7224 9.0079 29.7393 9.04874 29.7537 9.10159C29.7729 9.15444 29.7825 9.2121 29.7825 9.27456C29.7825 9.37546 29.7585 9.45474 29.7104 9.5124C29.6624 9.56525 29.5951 9.59168 29.5086 9.59168H27.5267ZM27.5267 11.5376V10.9178H29.9555C29.9747 10.9466 29.9915 10.9875 30.0059 11.0403C30.0251 11.0932 30.0348 11.1508 30.0348 11.2133C30.0348 11.3142 30.0107 11.3935 29.9627 11.4511C29.9146 11.5088 29.8474 11.5376 29.7609 11.5376H27.5267ZM27.5267 7.76826V7.14844H29.9194C29.9387 7.17727 29.9555 7.21811 29.9699 7.27096C29.9891 7.32381 29.9987 7.38147 29.9987 7.44393C29.9987 7.54483 29.9747 7.62411 29.9267 7.68177C29.8786 7.73943 29.8113 7.76826 29.7248 7.76826H27.5267Z"
                    fill="#26A69B"
                  />
                  <path
                    d="M24.5478 11.0108C24.8457 11.0108 25.0691 10.946 25.2181 10.8162C25.367 10.6865 25.4415 10.5232 25.4415 10.3262C25.4415 10.1532 25.3838 10.0114 25.2685 9.90093C25.1532 9.79042 24.9754 9.69913 24.7352 9.62706L24.1874 9.46129C23.9664 9.39402 23.7742 9.30994 23.6109 9.20904C23.4475 9.10814 23.3202 8.98081 23.2289 8.82705C23.1376 8.6733 23.0919 8.48832 23.0919 8.2721C23.0919 7.89252 23.2313 7.59462 23.51 7.37841C23.7934 7.15739 24.173 7.04688 24.6487 7.04688C24.913 7.04688 25.1484 7.0757 25.355 7.13336C25.5664 7.18621 25.7298 7.25829 25.8451 7.34958C25.9652 7.44087 26.0253 7.54417 26.0253 7.65949C26.0253 7.73636 26.006 7.80603 25.9676 7.8685C25.9292 7.92615 25.8787 7.9742 25.8163 8.01264C25.7009 7.92135 25.5448 7.84207 25.3478 7.7748C25.1508 7.70754 24.9346 7.6739 24.6991 7.6739C24.4397 7.6739 24.2355 7.72916 24.0865 7.83967C23.9376 7.94537 23.8631 8.08952 23.8631 8.2721C23.8631 8.41624 23.916 8.53396 24.0217 8.62525C24.1274 8.71174 24.2931 8.78862 24.519 8.85588L24.9442 8.98561C25.343 9.10093 25.6529 9.26429 25.8739 9.4757C26.0997 9.68231 26.2127 9.96579 26.2127 10.3262C26.2127 10.7153 26.0685 11.0301 25.7802 11.2703C25.4919 11.5105 25.0835 11.6307 24.555 11.6307C24.2619 11.6307 24.0024 11.597 23.7766 11.5298C23.5556 11.4673 23.3826 11.3856 23.2577 11.2847C23.1328 11.179 23.0703 11.0661 23.0703 10.946C23.0703 10.8499 23.0991 10.7706 23.1568 10.7081C23.2145 10.6409 23.2793 10.5904 23.3514 10.5568C23.4715 10.6625 23.6301 10.7658 23.8271 10.8667C24.0289 10.9628 24.2691 11.0108 24.5478 11.0108Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.81933 39.0971C14.6456 39.0971 18.5581 35.1847 18.5581 30.3584C18.5581 25.5321 14.6456 21.6197 9.81933 21.6197C4.99305 21.6197 1.08059 25.5321 1.08059 30.3584C1.08059 35.1847 4.99305 39.0971 9.81933 39.0971ZM9.81933 39.998C15.1432 39.998 19.459 35.6822 19.459 30.3584C19.459 25.0346 15.1432 20.7188 9.81933 20.7188C4.4955 20.7188 0.179688 25.0346 0.179688 30.3584C0.179688 35.6822 4.4955 39.998 9.81933 39.998Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.1807 0.902871C25.3544 0.902871 21.4419 4.81534 21.4419 9.64161C21.4419 14.4679 25.3544 18.3803 30.1807 18.3803C35.0069 18.3803 38.9194 14.4679 38.9194 9.64161C38.9194 4.81534 35.0069 0.902872 30.1807 0.902871ZM30.1807 0.00196945C24.8568 0.00196898 20.541 4.31778 20.541 9.64161C20.541 14.9654 24.8568 19.2812 30.1807 19.2812C35.5045 19.2812 39.8203 14.9654 39.8203 9.64161C39.8203 4.31778 35.5045 0.00196991 30.1807 0.00196945Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.77529 -0.0117188L19.5447 9.90956H14.9329V22.2519H14.032V9.00866H17.3932L9.77529 1.27229L2.15732 9.00866H5.51853V22.2519H4.61763V9.90956H0.00585938L9.77529 -0.0117188Z"
                    fill="#26A69B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M30.1349 40.0117L20.3654 30.0904L24.9772 30.0904L24.9772 17.7481L25.8781 17.7481L25.8781 30.9913L22.5169 30.9913L30.1349 38.7277L37.7528 30.9913L34.3916 30.9913L34.3916 17.7481L35.2925 17.7481L35.2925 30.0904L39.9043 30.0904L30.1349 40.0117Z"
                    fill="#26A69B"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9345_163830">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="promotion-banner__card__title">
              Start
              <br className="mobile-breakpoint" /> selling/buying
            </div>
            <div className="promotion-banner__card__description">
              Create your digital profile registering your company data and uploading your
              products
            </div>
          </div>
          <div
            className="col-12 col-md-4 promotion-banner__card"
            onClick={() => history.push('/secured/register')}
          >
            <div className="promotion-banner__card__icon">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.361328 15.8562C0.361328 10.9801 4.31413 7.02734 9.19016 7.02734H30.8118C35.6878 7.02734 39.6406 10.9801 39.6406 15.8562C39.6406 20.7322 35.6878 24.685 30.8118 24.685H9.19016C4.31413 24.685 0.361328 20.7322 0.361328 15.8562Z"
                  fill="#DEFCEC"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M30.8108 7.56496H9.18919C4.61169 7.56496 0.900901 11.2758 0.900901 15.8533C0.900901 20.4307 4.61169 24.1415 9.18919 24.1415H30.8108C35.3883 24.1415 39.0991 20.4307 39.0991 15.8533C39.0991 11.2758 35.3883 7.56496 30.8108 7.56496ZM9.18919 6.66406C4.11414 6.66406 0 10.7782 0 15.8533C0 20.9283 4.11414 25.0424 9.18919 25.0424H30.8108C35.8859 25.0424 40 20.9283 40 15.8533C40 10.7782 35.8859 6.66406 30.8108 6.66406H9.18919Z"
                  fill="#26A69B"
                />
                <path
                  d="M24.5898 13.6501L25.5736 13.5961C25.6025 13.8483 25.6277 14.1258 25.6493 14.4285C25.6709 14.7312 25.6853 15.0375 25.6925 15.3474C25.6998 15.6501 25.7034 15.942 25.7034 16.2231V19.0772C25.6601 19.0916 25.588 19.1096 25.4871 19.1312C25.3934 19.1528 25.2962 19.1636 25.1952 19.1636C24.9862 19.1636 24.8313 19.124 24.7304 19.0447C24.6367 18.9654 24.5898 18.8357 24.5898 18.6555V13.6501ZM29.9844 17.9745L28.9898 18.0177C28.925 17.4411 28.8853 16.8645 28.8709 16.288C28.8637 15.7042 28.8601 15.142 28.8601 14.6015V12.5366C28.9178 12.5222 28.9934 12.5078 29.0871 12.4934C29.1808 12.4717 29.2781 12.4609 29.379 12.4609C29.588 12.4609 29.7394 12.5006 29.8331 12.5799C29.934 12.6519 29.9844 12.7817 29.9844 12.969V17.9745ZM29.9844 17.5853V17.2069V18.9474C29.9124 19.0123 29.8115 19.0627 29.6817 19.0988C29.5592 19.142 29.4187 19.1636 29.2601 19.1636C29.0655 19.1636 28.8925 19.1312 28.7412 19.0663C28.5898 18.9943 28.4565 18.8501 28.3412 18.6339L26.5358 15.4988C26.4349 15.3114 26.3268 15.1132 26.2115 14.9042C26.1034 14.6952 25.9989 14.4934 25.898 14.2988C25.797 14.1042 25.7142 13.9348 25.6493 13.7907L24.5898 14.0393V12.7096C24.6619 12.6303 24.7628 12.569 24.8925 12.5258C25.0223 12.4826 25.1628 12.4609 25.3142 12.4609C25.5088 12.4609 25.6817 12.497 25.8331 12.569C25.9844 12.6411 26.1178 12.7817 26.2331 12.9907L28.0493 16.1258C28.1574 16.306 28.2655 16.5042 28.3736 16.7204C28.4817 16.9294 28.5826 17.1312 28.6763 17.3258C28.7772 17.5204 28.8601 17.6898 28.925 17.8339L29.9844 17.5853Z"
                  fill="#26A69B"
                />
                <path
                  d="M21.6465 14.9582H22.7708V19.0772C22.7276 19.0916 22.6555 19.1096 22.5546 19.1312C22.4609 19.1528 22.36 19.1636 22.2519 19.1636C22.0429 19.1636 21.8879 19.124 21.787 19.0447C21.6933 18.9582 21.6465 18.8213 21.6465 18.6339V14.9582ZM22.7708 16.1258H21.6465V12.5366C21.6969 12.5222 21.769 12.5078 21.8627 12.4934C21.9636 12.4717 22.0681 12.4609 22.1762 12.4609C22.3852 12.4609 22.5366 12.5006 22.6303 12.5799C22.724 12.6519 22.7708 12.7853 22.7708 12.9799V16.1258Z"
                  fill="#26A69B"
                />
                <path
                  d="M20.1931 15.8128C20.1931 16.5624 20.0526 17.193 19.7715 17.7047C19.4904 18.2164 19.1084 18.602 18.6255 18.8615C18.1427 19.1209 17.5949 19.2507 16.9823 19.2507C16.3769 19.2507 15.8291 19.1209 15.3391 18.8615C14.8562 18.602 14.4742 18.2164 14.1931 17.7047C13.912 17.193 13.7715 16.5624 13.7715 15.8128C13.7715 15.0633 13.9156 14.4327 14.2039 13.9209C14.4922 13.4092 14.8778 13.0236 15.3607 12.7642C15.8436 12.5047 16.3841 12.375 16.9823 12.375C17.5877 12.375 18.1318 12.5047 18.6147 12.7642C19.0976 13.0236 19.4796 13.4092 19.7607 13.9209C20.049 14.4327 20.1931 15.0633 20.1931 15.8128ZM19.0255 15.8128C19.0255 15.0056 18.8382 14.3894 18.4634 13.9642C18.0958 13.5318 17.6021 13.3155 16.9823 13.3155C16.3697 13.3155 15.876 13.5318 15.5012 13.9642C15.1264 14.3894 14.9391 15.0056 14.9391 15.8128C14.9391 16.62 15.1228 17.2399 15.4904 17.6723C15.8652 18.0975 16.3625 18.3101 16.9823 18.3101C17.6093 18.3101 18.1066 18.0975 18.4742 17.6723C18.8418 17.2399 19.0255 16.62 19.0255 15.8128Z"
                  fill="#26A69B"
                />
                <path
                  d="M11.2243 15.9745H12.3595V17.4123C12.3595 17.8447 12.2766 18.1943 12.1108 18.4609C11.9451 18.7276 11.7216 18.9222 11.4406 19.0447C11.1667 19.1672 10.8532 19.2285 10.5 19.2285C10.1036 19.2285 9.79731 19.1745 9.58109 19.0663C9.37208 18.9582 9.26758 18.7997 9.26758 18.5907C9.26758 18.4754 9.2892 18.3781 9.33244 18.2988C9.37569 18.2195 9.41893 18.1582 9.46217 18.115C9.57749 18.1582 9.70361 18.1979 9.84055 18.2339C9.9847 18.2699 10.1288 18.288 10.273 18.288C10.5901 18.288 10.8279 18.2123 10.9865 18.0609C11.1451 17.9024 11.2243 17.6537 11.2243 17.315V15.9745ZM12.3595 16.3745H11.2243V12.5366C11.2748 12.5222 11.3469 12.5078 11.4406 12.4934C11.5415 12.4717 11.6424 12.4609 11.7433 12.4609C11.9523 12.4609 12.1072 12.5006 12.2081 12.5799C12.309 12.6519 12.3595 12.7853 12.3595 12.9799V16.3745Z"
                  fill="#26A69B"
                />
                <path
                  d="M17.342 22.8012L18.9871 32.1234C19.046 32.4576 19.4921 32.5324 19.657 32.2358L21.1612 29.5281C21.2775 29.3188 21.5617 29.279 21.731 29.4483L25.2791 32.9964C25.4199 33.1371 25.648 33.1371 25.7888 32.9964L27.9547 30.8305C28.0954 30.6897 28.0954 30.4616 27.9547 30.3208L24.4066 26.7727C24.2373 26.6035 24.2771 26.3192 24.4864 26.2029L27.194 24.6987C27.4907 24.5338 27.4159 24.0878 27.0817 24.0288L17.7595 22.3837C17.5131 22.3402 17.2985 22.5548 17.342 22.8012Z"
                  fill="#26A69B"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.8076 22.8523L19.3862 31.7976L20.7671 29.312C21.0287 28.8411 21.6683 28.7515 22.0492 29.1324L25.5336 32.6168L27.5721 30.5783L24.0877 27.0939C23.7068 26.713 23.7964 26.0734 24.2673 25.8118L26.7529 24.4309L17.8076 22.8523ZM16.898 22.8821C16.8002 22.3278 17.2831 21.8449 17.8374 21.9427L27.1596 23.5878C27.9115 23.7205 28.0799 24.7242 27.4124 25.0951L24.8092 26.5413L28.2728 30.005C28.5895 30.3216 28.5895 30.835 28.2728 31.1516L26.1069 33.3175C25.7903 33.6342 25.2769 33.6342 24.9602 33.3175L21.4966 29.8539L20.0503 32.4572C19.6795 33.1246 18.6758 32.9563 18.5431 32.2043L16.898 22.8821Z"
                  fill="#26A69B"
                />
              </svg>
            </div>
            <div className="promotion-banner__card__title">
              Join us as
              <br className="mobile-breakpoint" /> value partner
            </div>
            <div className="promotion-banner__card__description">
              Provide your services in the marketplace or promote it to your corporate
              customers
            </div>
          </div>
          {/* <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <div className={styles.banner}>
              <div className={styles.icon}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.6667 13.3346C21.6667 9.6513 18.6833 6.66797 15 6.66797C11.3167 6.66797 8.33332 9.6513 8.33332 13.3346C8.33332 17.018 11.3167 20.0013 15 20.0013C18.6833 20.0013 21.6667 17.018 21.6667 13.3346ZM18.3333 13.3346C18.3333 15.168 16.8333 16.668 15 16.668C13.1667 16.668 11.6667 15.168 11.6667 13.3346C11.6667 11.5013 13.1667 10.0013 15 10.0013C16.8333 10.0013 18.3333 11.5013 18.3333 13.3346ZM1.66666 30.0013V33.3346H28.3333V30.0013C28.3333 25.568 19.45 23.3346 15 23.3346C10.55 23.3346 1.66666 25.568 1.66666 30.0013ZM4.99999 30.0013C5.33332 28.818 10.5 26.668 15 26.668C19.4833 26.668 24.6333 28.8013 25 30.0013H4.99999ZM33.3333 25.0013V20.0013H38.3333V16.668H33.3333V11.668H30V16.668H25V20.0013H30V25.0013H33.3333Z"
                    fill="#00968A"
                  />
                </svg>
              </div>
              <h3>Sign up to shop</h3>
              <p>
                Register in minutes and access to the TTW catalog of products from all
                over the world
              </p>
              <div>
                <Link to="/secured/register">Create Account</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 mb-4 mb-lg-0">
            <div className={styles.banner}>
              <div className={styles.icon}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 3.33203C10.8 3.33203 3.33334 10.7987 3.33334 19.9987C3.33334 29.1987 10.8 36.6654 20 36.6654C29.2 36.6654 36.6667 29.1987 36.6667 19.9987C36.6667 10.7987 29.2 3.33203 20 3.33203ZM20 33.332C12.65 33.332 6.66667 27.3487 6.66667 19.9987C6.66667 12.6487 12.65 6.66536 20 6.66536C27.35 6.66536 33.3333 12.6487 33.3333 19.9987C33.3333 27.3487 27.35 33.332 20 33.332ZM20.5167 18.5654C17.5667 17.8154 16.6167 16.9987 16.6167 15.782C16.6167 14.382 17.9333 13.3987 20.1167 13.3987C22.4167 13.3987 23.2833 14.4987 23.35 16.132H26.2C26.1167 13.8987 24.75 11.8487 22.05 11.182V8.33203H18.1667V11.1487C15.65 11.682 13.6333 13.3154 13.6333 15.832C13.6333 18.8154 16.1167 20.3154 19.7333 21.182C22.9833 21.9487 23.6333 23.0987 23.6333 24.2987C23.6333 25.182 22.9833 26.6154 20.1333 26.6154C17.4667 26.6154 16.4167 25.4154 16.2667 23.882H13.4C13.5667 26.7154 15.6667 28.3154 18.1667 28.832V31.6654H22.0667V28.882C24.6 28.3987 26.6 26.9487 26.6167 24.2654C26.6 20.5987 23.45 19.332 20.5167 18.5654V18.5654Z"
                    fill="#00968A"
                  />
                </svg>
              </div>
              <h3>Sell on TradeToWorld</h3>
              <p>
                Create your digital profile registering your company data and uploading
                your products
              </p>
              <div>
                <Link to="/secured/register">Apply Here</Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className={styles.banner}>
              <div className={styles.icon}>
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15 22.9154C11.1 22.9154 3.33331 24.8654 3.33331 28.7487V31.6654H26.6666V28.7487C26.6666 24.8654 18.9 22.9154 15 22.9154ZM7.23331 28.332C8.63331 27.3654 12.0166 26.2487 15 26.2487C17.9833 26.2487 21.3666 27.3654 22.7666 28.332H7.23331ZM15 19.9987C18.2166 19.9987 20.8333 17.382 20.8333 14.1654C20.8333 10.9487 18.2166 8.33203 15 8.33203C11.7833 8.33203 9.16665 10.9487 9.16665 14.1654C9.16665 17.382 11.7833 19.9987 15 19.9987ZM15 11.6654C16.3833 11.6654 17.5 12.782 17.5 14.1654C17.5 15.5487 16.3833 16.6654 15 16.6654C13.6166 16.6654 12.5 15.5487 12.5 14.1654C12.5 12.782 13.6166 11.6654 15 11.6654ZM26.7333 23.0154C28.6666 24.4154 30 26.282 30 28.7487V31.6654H36.6666V28.7487C36.6666 25.382 30.8333 23.4654 26.7333 23.0154V23.0154ZM25 19.9987C28.2166 19.9987 30.8333 17.382 30.8333 14.1654C30.8333 10.9487 28.2166 8.33203 25 8.33203C24.1 8.33203 23.2666 8.5487 22.5 8.91536C23.55 10.3987 24.1666 12.2154 24.1666 14.1654C24.1666 16.1154 23.55 17.932 22.5 19.4154C23.2666 19.782 24.1 19.9987 25 19.9987Z"
                    fill="#00968A"
                  />
                </svg>
              </div>
              <h3>Join us as partners</h3>
              <p>
                You want to provide your services in the marketplace or promote it to your
                corporate customers? Write us to our form below
              </p>
              <div>
                <Link to="/secured/register">Join Us</Link>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PromotionBanner
